@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@layer base {
  body {
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }

  h1 {
    @apply text-3xl md:text-4xl mb-2;
  }

  h2 {
    @apply text-2xl md:text-3xl mb-4;
  }

  h3 {
    @apply text-xl md:text-2xl mb-2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-light leading-tight;
  }

  p {
    @apply text-base;
  }
}

@layer components {
  .btn {
    @apply px-5 py-3 font-medium outline-none rounded-full transition-all duration-200 inline-flex
          disabled:cursor-not-allowed disabled:opacity-40 shadow hover:shadow-lg justify-center items-center
          text-center text-lg;
  }
  .btn-primary {
    @apply text-white bg-primary-600 hover:bg-primary-400 hover:text-black
          focus:bg-primary-400 focus:text-black focus-within:bg-primary-400 focus-within:text-black
          disabled:focus:bg-primary-600 disabled:hover:bg-primary-600 disabled:hover:text-white
          disabled:focus-within:bg-primary-600 disabled:focus:text-white disabled:focus-within:text-white;
  }
  .btn-outline {
    @apply text-primary-600 bg-white hover:bg-primary-400 hover:text-black ring-2
    focus:bg-primary-400 focus:text-black focus-within:bg-primary-400 focus-within:text-black
    ring-primary-600 hover:ring-primary-400 focus:ring-primary-400 focus-within:ring-primary-400;
  }
}
